  
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link as LinkR } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
// import Chart from './Chart';
import logo from './fierosprite3.gif';
import Main from './Main.js';
import Main201920 from './Main201920.js';
import About from './Components/About.js'
import Positions from './Components/Positions.js'
import SideNav from './Components/SideNav.js'
import SideNavR from './Components/SideNavR.js'
import Table from './Components/LeagueTable.js';
import LeagueTable from './Components/LeagueTable.js';
import bgImage from './grass-header.jpg';

import Deposits from './Deposits';
import Orders from './Orders';
import Header from './Components/Header.js'
import {useStyles} from './Components/sideStyle.js'



export default function App() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);  
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return (
      React.createElement(component, finalProps)
    );
  }

  //const serverRoute = "https://api-adw3txonqq-uc.a.run.app/";  //app engine
  //const serverRoute = "http://localhost/";
  const serverRoute = 'https://us-central1-fanfiero.cloudfunctions.net/'; //firebase
  
  const PropsRoute = ({ component, ...rest }) => {
    return (
      <Route {...rest} render={routeProps => {
        return renderMergedProps(component, routeProps, rest);
      }}/>
    );
  }

  return (
    <div style={{ height: '100vh'}}>
    <div className={classes.root}  >
      <Router>
      <CssBaseline />

      {/* <Header/> */}
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>

            <span className="Titan">
                <img className="Logo" src={logo} alt='cool stuff'/>&nbsp;
                <span className="Orange">Fan</span>
                <span className="Red">Fiero</span>
                <span className="Yellow">&nbsp;2020-2021 Edition!</span>
            </span> 
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* <SideNavR/> */}



      <Drawer containerStyle={{height: '100%'}}
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >


        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <List>{mainListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
        <Divider/>
      </Drawer>



        <Switch>
          <PropsRoute exact path="/" component={Main} serverRoute={serverRoute}  />
          <PropsRoute path="/table" component={LeagueTable} serverRoute={serverRoute}  />
          <PropsRoute path="/about" component={About} serverRoute={serverRoute}  />
          <PropsRoute path="/201920" component={Main201920} serverRoute={serverRoute}  />
          <PropsRoute path="/positions" component={Positions} serverRoute={serverRoute} />
          {/* <Route component={NoMatch} /> */}
        </Switch>
      </Router>

    </div></div>
  );
}

class NavItem extends React.Component {
  handleClick = () => {
      const { path, onItemClick } = this.props;
      console.log('sd: path?: ' + path)
      //onItemClick(path);
  }

  render() {
      const { active } = this.props;
      console.log('sd: this.props.path:' + this.props.path);
      return(
          // <StyledNavItem active={active}>
              <LinkR to={this.props.path} className={this.props.css} onClick={this.handleClick}>
                  {this.props.name}
              </LinkR>
          //{/* </StyledNavItem> */}
      );
  }
}

// {/* <Container maxWidth="lg" className={classes.container}>
// <Grid container spacing={3}>
//   {/* Chart */}
//   <Grid item xs={12} md={8} lg={9}>
//     <Paper className={fixedHeightPaper}>
//       {/* <Chart /> */}
//     </Paper>
//   </Grid>
//   {/* Recent Deposits */}
//   <Grid item xs={12} md={4} lg={3}>
//     <Paper className={fixedHeightPaper}>
//       <Deposits />
//     </Paper>
//   </Grid>
//   {/* Recent Orders */}
//   <Grid item xs={12}>
//     <Paper className={classes.paper}>
//       <Orders />
//     </Paper>
//   </Grid>
// </Grid>
// <Box pt={4}>
//   <Copyright />
// </Box>
// </Container> */}