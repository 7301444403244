
import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';

const data = {
  labels: ['Hazard', 'Mendy', 'Alonso', 'Delph', 'Sterling', 'Mane', 'Salah'],
  datasets: [
    {
      label: 'PTS',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,1)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40]
    }, {
      label: "COST",
      fill: false,
      data: [44, 45, 46, 47, 48, 49, 88],
    }
  ]
};

class WeeklyChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            players: [],
        };
        
        //alert(this.state.iframeUrl);
    }

    shouldComponentUpdate(nextProps, nextState){
      // fires for every change 
      console.log("shouldComponentUpdate Line1.js");

      //TODO: this is not good, better error checking
      if (nextProps.rowData == null) return true;
      //console.log(nextProps.rowData.length);
      //console.log(this.props.rowData.length);
      if (this.props.rowData.length === nextProps.rowData.length){
          //console.log("not equal data");
          return false;
      }
      return true;
    }

    dataSetFactory(label, col, color, multiplier){
      //const color = 'rgba(75,192,192,1)';
      const playerCount = 10;
      const p = this.props.rowData;
      const m = multiplier;

      return     {
        label: label,
        fill: false,
        lineTension: 0.2,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 1.5,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: color,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: p.slice(0, playerCount).map(i => i[col] * m),
      };
    }

    getTopPlayers() {
      const p = this.props.rowData;
      var playerCount = 10;
      var playerNames = p.slice(0, playerCount).map(i => i.name);
      return playerNames;
    }

    getDataSets(){
      const dataSets = [];
      dataSets.push(this.dataSetFactory('Points', 'pts', 'blue', 1));
      dataSets.push(this.dataSetFactory('PPG', 'ppg', 'green', 10));
      dataSets.push(this.dataSetFactory('Cost', 'now_cost', 'red', 1));
      dataSets.push(this.dataSetFactory('Pts Pnd', 'pts_pound', 'purple', 10));
      dataSets.push(this.dataSetFactory('AdjPPG', 'adj_avg_pts', 'orange', 10));
      return dataSets;
    }

    transcodeDataForGraph(){
      const data = {
        labels: this.getTopPlayers(),
        datasets: this.getDataSets(),
      };

      //console.log(data);
      return data;
    }
  
  
    render() {
      //const beginningWeek = this.props.beginningWeek;
      //const endingWeek = this.props.endingWeek;
      //this.getTopPlayers();
      //console.log("render Line1.js");
      //const chartData = data;
      const chartData = this.transcodeDataForGraph();
      //console.log(chartData);
        return (
          <div  style={{ backgroundColor: 'white' }}>
          
            <Line data={chartData} height={250} options={{maintainAspectRatio: false, overflow: 'auto'}} />
          </div>
        );
      }
  }
  
export default WeeklyChart;

