import React, { Component } from 'react';
//import Container from 'react-bootstrap/Container';
//import Row from 'react-bootstrap/Row';
//import Col from 'react-bootstrap/Col';


//import { Container, Col, Row } from 'react-bootstrap';
//import { Container, Col, Row } from 'react';
 
import './App.css';
import logo from './fierosprite3.gif';
//import Weekly from './Weekly.js';
//import Modal from './Modal.js';
//import Metabase from './Metabase.js';
import WeeklyData from './WeeklyData.js'
import 'bootstrap/dist/css/bootstrap.min.css'


class Main201920 extends Component {


  constructor(props) {
      console.log('sd: props' + props);
      console.log(props);
      super();
      this.state = {
          season: props.location.season,
          currentWeek: 38,
          leagueTable: null,
          tableUpdate: -1,
          tableDate: null,
          //server: "http://fanfiero.com/",
          //server: "https://api-adw3txonqq-uc.a.run.app/",
          //server: "http://localhost:80/",
          server: props.serverRoute,
          
      };
  }

  componentWillMount() {
    //this.getInitialData();
  }

  getInitialData() {
    console.log('sd: season before getting data: ' + this.state.season);
    console.log('sd: argument:', this.props.location.season);
    fetch(this.state.server + "/currentWeek")
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson.dataSet);
        console.log(responseJson.dataSet[0].latestWeek);
        this.setState({ currentWeek: responseJson.dataSet[0].latestWeek})
      })
      .catch((error) => {
        console.error(error);
      })
    // fetch(this.state.server + "//leagueTable")
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     console.log(responseJson.dataSet);
    //     //console.log(responseJson.dataSet[0].latestWeek);
    //     this.setState({ leagueTable: responseJson.dataSet[0].latestWeek})
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   })

  }



  render() {

    return (
      <>

    {/* 
      <div style={{ background: '#333333', color: '#FFF700'}}>Data updated for week: {this.state.currentWeek}. UPDATING FOR 2020-2021!</div>
    */}
    {/* <div className="container" style={{ background: 'white'}} >    
      <div className="row">
        <div className = "col"> */}
          
          <WeeklyData 
                currentWeek = { this.state.currentWeek }
                server = { this.state.server }
                season = '201920'/>
        {/* </div>
      </div>
    </div> */}
    </>)
  } 


}
export default Main201920;
