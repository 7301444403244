import React, { Component} from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

class WeeklyGrid extends Component {
    constructor(props) {
        super(props);
        this.dataCacheLength = -1;
        this.state = {
            columnDefs: [
                {headerName: "P", field:"pos", width: 40},
                {headerName: "T", field:"team", width: 60},
                {headerName: "Name", field:"name", width: 100, pinned: "left"},
                {headerName: "Stat", field:"status", width: 40 },
                {headerName: "Cost", field:"now_cost", width: 80 },
                {headerName: "SelPct", field:"selected_pct", width: 80 },
                {headerName: "Chg", field:"cost_change_start", width: 70 },
                {headerName: "Games", field:"games", width: 80 },
                {headerName: "Mins", field:"minutes", width: 80 },
                {headerName: "AvgMins", field:"avg_minutes", width: 90 },
                {headerName: "Pts", field:"pts", width: 80 },
                {headerName: "MinPts", field:"min_game_pts", width: 90 },

                {headerName: "PPG", field:"ppg", width: 80 },
                {headerName: "MaxPts", field:"max_game_pts", width: 90 },
                {headerName: "STD", field:"std_pts", width: 80 },
                {headerName: "AdjAP", field:"adj_avg_pts", width: 90 },
                {headerName: "Pts$", field:"pts_pound", width: 80 },
                {headerName: "PPMin", field:"ppm", width: 90 },
                {headerName: "AvgAsst", field:"avg_assists", width: 90 },
                //{headerName: "AvAttPs", field:"avg_attempted_passes", width: 90 },
                //{headerName: "AvgBCM", field:"avg_big_chances_missed", width: 90 },
                {headerName: "AvgBns", field:"avg_bonus", width: 90 },
                {headerName: "AvgBPS", field:"avg_bps", width: 90 },
            ],
        };
    }


    shouldComponentUpdate(nextProps, nextState){
        // fires for every change 
        console.log("shouldComponentUpdate WeeklyGrid.js");

        //TODO: this is not good, better error checking
        if (nextProps.rowData == null) return true;
        //console.log(nextProps.rowData.length);
        //console.log(this.props.rowData.length);
        if (this.props.rowData.length === nextProps.rowData.length){
            //console.log("not equal data");
            return false;
        }
        return true;
      }

    render() {
        console.log("render WeeklyGrid.js");

        return (
            
            <div className="ag-theme-balham" style={{ height: '400px', width: '100%' }}>
            <AgGridReact    enableFilter={true} 
                            enableSorting={true} 
                            columnDefs={this.state.columnDefs} 
                            rowData={this.props.rowData} >
            </AgGridReact>
            </div>
            
        )
    }
}

export default WeeklyGrid;