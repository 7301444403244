import React, { Component} from 'react';
import WeeklyGrid from './WeeklyGrid.js';
import WeeklyChart from './WeeklyChart.js';
import SearchBar2 from './SearchBar2.js';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Player from './Player.js';

class WeeklyData extends Component {
    constructor(props) {
        super(props);
        console.log('sd: season: ' + props.season);
        let season = '2020/2021';
        if (props.season === '201920') season = '2019/2020';
        this.state = {
            rowData: [],
            beginningWeek: 1,
            endingWeek: props.currentWeek,
            maxWeek: props.currentWeek,
            minGames : 0,
            position: 0,
            season: season,
        };
        console.log("fed in currentweek: " + props.currentWeek);
        this.handleBeginningWeekTextChange = this.handleBeginningWeekTextChange.bind(this);
        this.handleEndingWeekTextChange = this.handleEndingWeekTextChange.bind(this);
        this.handlePositionTextChange = this.handlePositionTextChange.bind(this);
        this.handleMinGamesTextChange = this.handleMinGamesTextChange.bind(this);

        this.getVWeeklyData = this.getVWeeklyData.bind(this);
    
    }

    componentDidMount() {
        this.getVWeeklyData();
    }

    getVWeeklyData(newStateObject){
        const startWeek = newStateObject && newStateObject.beginningWeek ? newStateObject.beginningWeek : this.state.beginningWeek;
        const endWeek = newStateObject && newStateObject.endingWeek ? newStateObject.endingWeek : this.state.endingWeek;
        const position = newStateObject && newStateObject.position ? newStateObject.position : this.state.position;
        const min = newStateObject && newStateObject.minGames ? newStateObject.minGames : this.state.minGames;

        var url = this.props.server + 'weekly?';
        //endWeek=' + endWeek + '&position=' + position + '&startWeek=' + startWeek + '&min=' + min;
        if (endWeek != null) url += 'endWeek=' + endWeek + "&";
        url += 'position=' + position + '&startWeek=' + startWeek + '&min=' + min;
        if (this.state.season === '2019/2020') url = url + '&year=201920';
        
        //console.log(url);
        fetch(url)
        .then( results => {
            return results.json();
        }).then(data => {
            let pl=data.dataSet;
            let newState = {rowData: pl};
            if (newStateObject){
                for (var attrname in newStateObject) { newState[attrname] = newStateObject[attrname]; }
            }
            //console.log('SETTING STATE');
            //console.log(newState);
            this.setState(newState);
            //console.log(this.state.endingWeek);
        });
    }

    handleBeginningWeekTextChange(beginningWeek) {
        this.getVWeeklyData({ beginningWeek: beginningWeek });
    }
    handleEndingWeekTextChange(endingWeek) {
        this.getVWeeklyData({ endingWeek: endingWeek });
    }
    handlePositionTextChange(position) {       
        this.getVWeeklyData({ position: position });
    }

    handleMinGamesTextChange(minGames){
        this.getVWeeklyData({ minGames: minGames });

    }

//currentWeek={this.props.currentWeek}
    render() {
        console.log('currentWeek: ' + this.props.currentWeek);
        return (
            <div className="container ag-theme-balham" 
                    style={{ height: '650px', width: '100%', marginTop: '70px', paddingTop: '10px', fontSize: '10px' }}>
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Paper >
                        <SearchBar2 
                            beginningWeek={this.state.beginningWeek}
                            endingWeek={this.state.endingWeek}                     
                            position={this.state.position}
                            onBeginningWeekTextChange={this.handleBeginningWeekTextChange}
                            onEndingWeekTextChange={this.handleEndingWeekTextChange}
                            onPositionTextChange={this.handlePositionTextChange} 
                            onMinGamesTextChange={this.handleMinGamesTextChange} 

                            maxWeek={this.props.currentWeek} 
                            
                            minGames={this.state.minGames} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper ><WeeklyChart rowData= {this.state.rowData} /></Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper ><WeeklyGrid rowData= {this.state.rowData} /></Paper>
                </Grid>
            </Grid>
            </div>




            // <div className='container' style = {{marginLeft: '70px', marginTop: '70px', paddingTop: '10px', fontSize: '10px'}}>
            // <div className="container ag-theme-balham" 
            //      style={{ marginLeft: '70px', height: '650px', width: '80%', marginTop: '70px', paddingTop: '10px', fontSize: '10px' }}>
            //     <div >


            // <div className="container ag-theme-balham" 
            //      style={{ height: '650px', width: '80%', marginTop: '70px', paddingTop: '10px', fontSize: '10px' }}>

            //     <span className="Titan" style ={{ fontSize: '20px'}} >
            //     {/* <img className="Logo" src={logo} alt='cool stuff'/>&nbsp; */}
            //     <span className="Red">{this.state.season} Statistics</span></span>

            //     <SearchBar2 
            //         beginningWeek={this.state.beginningWeek}
            //         endingWeek={this.state.endingWeek}                     
            //         position={this.state.position}
            //         onBeginningWeekTextChange={this.handleBeginningWeekTextChange}
            //         onEndingWeekTextChange={this.handleEndingWeekTextChange}
            //         onPositionTextChange={this.handlePositionTextChange} 
            //         onMinGamesTextChange={this.handleMinGamesTextChange} 

            //         maxWeek={this.props.currentWeek} 
                    
            //         minGames={this.state.minGames} />
            //     <div >
            //         <WeeklyChart rowData= {this.state.rowData} />
            //         <br/>
            //         <WeeklyGrid rowData= {this.state.rowData} />
            //     </div>
            // </div>
        );
    }
}

/*
                {this.state.endingWeek} {this.props.currentWeek}
                <Player />
                */

export default WeeklyData;
