//import 'bootstrap/dist/css/bootstrap.min.css';
//import $ from 'jquery';
//import Popper from 'popper.js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Dashboard from './App';
import * as serviceWorker from './serviceWorker';

var firebase = require('firebase');
var firebaseui = require('firebaseui');
// Initialize the FirebaseUI Widget using Firebase.
//firebase.initializeApp(config);
//var ui = new firebaseui.auth.AuthUI(firebase.auth());
// Initialize the FirebaseUI Widget using Firebase.
export const firebaseConfig = {
    apiKey: "AIzaSyC4umkOD-yfNmJOG6G-pMiUJ0qnYIKpc0Y",
    authDomain: "fanfiero.firebaseapp.com",
    databaseURL: "https://fanfiero.firebaseio.com",
    storageBucket: "fanfiero.appspot.com",
    messagingSenderId: ""
  };
// firebase.initializeApp(firebaseConfig);
// var ui = new firebaseui.auth.AuthUI(firebase.auth());
// ui.start('#firebaseui-auth-container', {
//     signInOptions: [
//       // List of OAuth providers supported.
//       firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//       //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
//       //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
//       //firebase.auth.GithubAuthProvider.PROVIDER_ID
//     ],
//     // Other config options...
//   });

//import 'bootstrap/dist/css/bootstrap.min.css'

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
