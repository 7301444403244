import React, { Component} from 'react';
import { Button, Modal, Popover, Tooltip, OverlayTrigger} from 'react-bootstrap';
import PlayerGrid from './PlayerGrid.js';

class Player extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
  
      this.state = {
        show: false,
        rowData: []
      };
    }

    componentDidMount() {
        //console.log('did mount');
        this.getPlayerData();
    }

    getPlayerData(){
        const server = 'http://localhost:8080/';
        //const server = 'http://fanfiero/';
        //const url = server + 'player?playerId=' + this.props.playerId;
        const url = server + 'player?playerId=1';

        console.log(url);
        fetch(url)
        .then( results => {
            return results.json();
        }).then(data => {
            //let pl=data.dataSet;
            //let newState = {rowData: pl};
            //if (newStateObject){
            //    for (var attrname in newStateObject) { newState[attrname] = newStateObject[attrname]; }
            //}
            console.log('SETTING STATE');
            console.log(data.dataSet);
            //console.log(newState);
            this.setState({ rowData: data.dataSet});
        });
    }
  
    handleClose() {
      this.setState({ show: false });
    }
  
    handleShow() {
      
      this.setState({ show: true });
    }
  
    render() {
      const popover = (
        <Popover id="modal-popover" title="popover">
          very popover. such engagement
        </Popover>
      );
      const tooltip = <Tooltip id="modal-tooltip">wow.</Tooltip>;
  
      return (
        <div>
  
          <Button bsStyle="primary" bsSize="large" onClick={this.handleShow}>
            View Player
          </Button>
  
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Text in a modal</h4>
              <p>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </p>

              <PlayerGrid rowData = {this.state.rowData} />
  
              <h4>Popover in a modal</h4>
              <p>
                there is a{' '}
                <OverlayTrigger overlay={popover}>
                  <a href="#popover">popover</a>
                </OverlayTrigger>{' '}
                here
              </p>
  
              <h4>Tooltips in a modal</h4>
              <p>
                there is a{' '}
                <OverlayTrigger overlay={tooltip}>
                  <a href="#tooltip">tooltip</a>
                </OverlayTrigger>{' '}
                here
              </p>
  
              <hr />
  
              <h4>Overflowing text to show scroll behavior</h4>
              <p>
                Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                ac consectetur ac, vestibulum at eros.
              </p>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur
                et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                auctor.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleClose}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }

  export default Player;