import React, { Component } from 'react';
import {Pie} from 'react-chartjs-2';
//import classes from '*.module.css';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

const data = {
	labels: [
		'Goalies',
		'Defenders',
		'Midfielders',
		'Strikers'
	],
	datasets: [{
		data: [300, 50, 100, 22],
		backgroundColor: [
		'#E7F7D4',
		'#7FBD32',
		'#EA1F2D',
		'#B8141F',
		],
		hoverBackgroundColor: [
		'#E7F7D4',
		'#7FBD32',
		'#EA1F2D',
		'#B8141F',
		]
	}]
};


class Positions extends Component {
	constructor(props) {
        super();
        this.state = {
			data_pts: null,
			data_mins: null,
			data_90: null,
			data_goals: null,
			data_goals_90: null,
			data_assists: null,
			data_assists_90: null,
			data_bonus: null,
			data_bonus_90: null,
			server: props.serverRoute,
		}
		this.formatData = this.formatData.bind(this);
	}

  //displayName: 'PieExample',
  	componentWillMount() {
		this.getInitialData();
	}

	formatData(data, type) {
		let datax = {};
		datax.datasets = [];
		let pts = {};
		let label = "helio mundo";
		if (type === 'pts') {
			pts.data = data.map(x => x.pts);
			label = 'Points';
		}
		if (type === 'mins') pts.data = data.map(x => x.mins);
		if (type === '90') pts.data = data.map(x => x.pts_per_90);
		if (type === 'g') pts.data = data.map(x => x.goals);
		if (type === 'g90') pts.data = data.map(x => x.goals_per_90);
		if (type === 'a') pts.data = data.map(x => x.assists);
		if (type === 'a90') pts.data = data.map(x => x.assists_per_90);
		if (type === 'b') pts.data = data.map(x => x.bonus);
		if (type === 'b90') pts.data = data.map(x => x.bonus_per_90);
		datax.labels = data.map(x => {
			if (x.element_type == 1) return 'Goalie';
			if (x.element_type == 2) return 'Defender';
			if (x.element_type == 3) return 'Midfielder';
			if (x.element_type == 4) return 'Forward';
		});
		pts.backgroundColor = ['#E7F7D4', '#7FBD32', '#EA1F2D', '#B8141F',];
		pts.hoverBackgroundColor = ['#E7F7D4', '#7FBD32', '#EA1F2D', '#B8141F',];
		datax.datasets.push(pts);
		//datax.datasets.push({label: label});
		datax.options =  {
			legend: {
				display: false,
				labels: {
					fontColor: 'rgb(255, 99, 132)'
				}
			}
		}
		return datax;
	}

	getInitialData() {
		fetch(this.state.server + "/positions")
			.then((response) => response.json())
			.then((responseJson) => {
			console.log('sd: response for league table')
			console.log(responseJson.dataSet);

			const data_pts = this.formatData(responseJson.dataSet, 'pts');
			const data_mins = this.formatData(responseJson.dataSet, 'mins');
			const data_90 = this.formatData(responseJson.dataSet, '90');
			const data_goals = this.formatData(responseJson.dataSet, 'g');
			const data_goals_90 = this.formatData(responseJson.dataSet, 'g90');
			const data_assists = this.formatData(responseJson.dataSet, 'a');
			const data_assists_90 = this.formatData(responseJson.dataSet, 'a90');
			const data_bonus = this.formatData(responseJson.dataSet, 'b');
			const data_bonus_90 = this.formatData(responseJson.dataSet, 'b90');
			console.log(data_pts);
			console.log(data_mins);
			console.log(data_90);
			this.setState({ data_mins: data_mins, data_pts: data_pts, data_90: data_90,
				data_goals: data_goals, data_goals_90, data_goals_90, 
				data_assists: data_assists, data_assists_90: data_assists_90,
				data_bonus: data_bonus, data_bonus_90: data_bonus_90});

			})
			.catch((error) => {
			console.error(error);
		}) 
	}


  	render() {
		if (!this.state.data_pts){
			return ( <i className="fa fa-spinner fa-spin"></i>);
		}
		console.log ('sd: we are in the renderer');
		console.log(this.state.data);
		console.log(data);
		const options = {legend: { display: false, labels: {fontColor: 'rgb(255, 99, 132)'}}, 
			title: {display: true, text: 'atari'}};
		const opto = (lbl) => {

			return {legend: { display: false, labels: {fontColor: 'rgb(255, 99, 132)'}}, 
			title: {display: true, text: lbl}};
		}
    	return (

			<div className="container ag-theme-balham" 
					style={{ height: '650px', width: '80%', marginTop: '70px', paddingTop: '10px', fontSize: '10px' }}>

				<span className="Titan" style ={{ fontSize: '20px'}} >
					<span className="Red">Positions</span>
	
				</span> 
				{/* {this.state.data_pts}
				{this.state.data_mins}
				{this.state.data_90} */}
				{/* <h2>Total Points</h2> */}
				<Grid container spacing={24}>
					<Grid item xs={6}>
						<Paper >
							<Pie data={this.state.data_pts} options={opto('Total Points')} />
						</Paper>
					</Grid>
					{/* <Grid item xs={4}>
						<Paper ><Pie data={this.state.data_mins} /></Paper>
					</Grid> */}
					<Grid item xs={6}>
						<Paper ><Pie label='Boo' data={this.state.data_90} options={opto('Point Per 90 Mins')} /></Paper>
					</Grid>
				</Grid>
				<Grid container spacing={24}>
					<Grid item xs={6}>
						<Paper ><Pie data={this.state.data_goals} options={opto('Total Goals')} /></Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper ><Pie data={this.state.data_goals_90} options={opto('Goals Per 90 Mins')} /></Paper>
					</Grid>
				</Grid>
				<Grid container spacing={24}>
					<Grid item xs={6}>
						<Paper ><Pie data={this.state.data_assists} options={opto('Total Assists')} /></Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper ><Pie data={this.state.data_assists_90} options={opto('Assists Per 90 Mins')} /></Paper>
					</Grid>
				</Grid>
				<Grid container spacing={24}>
					<Grid item xs={6}>
						<Paper ><Pie data={this.state.data_bonus} options={opto('Total Bonus')} /></Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper ><Pie data={this.state.data_bonus_90} options={opto('Bonus Per 90 Mins')} /></Paper>
					</Grid>
				</Grid>
			</div>
		)
	}

}

export default Positions;