import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import MGrid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Icon from '@material-ui/core/Icon';
 
import '../App.css';
import WeeklyData from '../WeeklyData.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import logo from '../fierosprite3.gif';


class LeagueTable extends Component {


    constructor(props) {
        super();
        this.state = {
            currentWeek: null,
            leagueTable: null,
            tableUpdate: -1,
            tableDate: null,
            //server: "http://fanfiero.com/",
            //server: "https://api-adw3txonqq-uc.a.run.app/",
            //server: "http://localhost:80/",
            server: props.serverRoute,
            columnDefs: [
              {headerName: "Team", field:"short_name", width: 80},
              {headerName: "P", field:"played", width: 50},
              {headerName: "W", field:"won", width: 50},
              {headerName: "D", field:"drawn", width: 50 },
              {headerName: "L", field:"lost", width: 50 },
              {headerName: "GF", field:"gf", width: 50 },
              {headerName: "GA", field:"ga", width: 50 },
              {headerName: "GD", field:"gd", width: 50 },
              {headerName: "Pts", field:"pts", width: 50 },
              {headerName: "Last 5", field:"last5", width: 150, cellRenderer: 'lastCellRenderer' },
              {headerName: "Upcoming", field:"opp_strength", width: 150, cellRenderer: 'strengthCellRenderer' },
              {headerName: "Opp", field:"strength5", width: 50 },
              {headerName: "Loc", field:"locations", width: 150, cellRenderer: 'homeCellRenderer' },
              {headerName: "Next 5", field:"display", width: 175 },
            ],
            resizer: (params) => {
              console.log('sd: resize');
              params.api.sizeColumnsToFit()
              window.setTimeout(() => {
                const colIds = params.columnApi.getAllColumns().map(c => c.colId)
                params.columnApi.autoSizeColumns(colIds)
              }, 50)
            }

            
        };
    }

    LastCellRenderer = class {
      win = '<i class="material-icons" style="color: green">star</i>';
      loss = '<i class="material-icons" style="color: red">star</i>';
      draw = '<i class="material-icons" style="color: gray">star_half</i>';
      bi = '<i class="material-icons">more_horiz</i>';

      init(params) {
        this.eGui = document.createElement('span');
        let result = '';
        console.log(params.value.length);
        for (let x = params.value.length; x<5; x++){
          //console.log(x);
          //console.log('sd: putt putt');
          result += this.bi;
        }
        params.value.split('').forEach(letter => {
          if (letter === 'W') result += this.win;
          if (letter === 'L') result += this.loss;
          if (letter === 'D') result += this.draw;
        });
        this.eGui.innerHTML = result;
      }

      getGui(){
        return this.eGui;
      }
    }

    StrengthCellRenderer = class {
      five= '<i class="material-icons" style="color: red">looks_5</i>';
      four = '<i class="material-icons" style="color: orange">looks_4</i>';
      three = '<i class="material-icons" style="color: yellow">looks_3</i>';
      two = '<i class="material-icons" style="color: green">looks_two</i>';

      init(params) {
        this.eGui = document.createElement('span');
        let result = '';
        params.value.split('').forEach(letter => {
          if (letter === '5') result += this.five;
          if (letter === '4') result += this.four;
          if (letter === '3') result += this.three;
          if (letter === '2') result += this.two;
        });
        this.eGui.innerHTML = result;
      }

      getGui(){
        return this.eGui;
      }
    }

    HomeCellRenderer = class {
      home = '<i class="material-icons" style="color: green">home</i>';
      away = '<i class="material-icons" style="color: red">work</i>';


      init(params) {
        this.eGui = document.createElement('span');
        let result = '';
        params.value.split('').forEach(letter => {
          if (letter === 'H') result += this.home;
          if (letter === 'A') result += this.away;

        });
        this.eGui.innerHTML = result;
      }

      getGui(){
        return this.eGui;
      }
    }

  
    componentWillMount() {
      this.getInitialData();
    }
  
    getInitialData() {
      fetch(this.state.server + "/leagueTable")
        .then((response) => response.json())
        .then((responseJson) => {
          console.log('sd: response for league table');
          // responseJson.dataSet.forEach(e => {
          //   e.last5 = '<Icon style={{ color: green[500] }}>add_circle</Icon>';
          // });
          this.setState({ leagueTable: responseJson.dataSet});
        })
        .catch((error) => {
          console.error(error);
        }) 
    }

    // onFirstDataRendered =  (params) => {
    //   params.api.sizeColumnsToFit()
    //   window.setTimeout(() => {
    //     const colIds = params.columnApi.getAllColumns().map(c => c.colId)
    //     params.columnApi.autoSizeColumns(colIds)
    //   }, 50)
    // }

    render() {
      if (this.state.leagueTable == null) {
        console.log('sd: no data yet');
        return ( <i className="fa fa-spinner fa-spin"></i>);
      }
      console.log('sd: we have data now')
      console.log(this.LastCellRenderer)

      return (
        <>

        

        <div className="container ag-theme-balham" 
                 style={{ height: '650px', width: '80%', marginTop: '70px', paddingTop: '10px', fontSize: '10px' }}>
            {/* <div  style={{ paddingLeft: '2px', paddingRight: '2px', 
            font: '600 8px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
            fontSize: '8px', height: '400px', width: '240px'}}  > */}

          <span className="Titan" style ={{ fontSize: '20px'}} >
            <img className="Logo" src={logo} alt='cool stuff'/>&nbsp;
            <span className="Red">Current Table</span>
          </span> 
          
          {/* <Icon style={{color: '#FF0000'}} >star</Icon> */}

          {/* <MGrid container spacing={24}><MGrid item xs={12}><Paper> */}
              <AgGridReact    //enableFilter={true} 
                          components= {{ 
                            'lastCellRenderer': this.LastCellRenderer,
                            'strengthCellRenderer': this.StrengthCellRenderer,
                            'homeCellRenderer': this.HomeCellRenderer,
                          }}
                          defaultColDef={{'resizable': true}}
                            //</div>lastCellRenderer: {this.LastCellRenderer},
                          enableSorting={true} 
                            //onFirstDataRendered={this.state.resizer}
                          columnDefs={this.state.columnDefs} 
                          rowData={this.state.leagueTable}
                            
                          domlayout='autoHeight' >
              </AgGridReact>
              {/* </Paper></MGrid></MGrid>Hya */}
        </div>

      </>)
    } 
}
export default LeagueTable;