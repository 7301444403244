import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Form, FormControl, Row,Col, FormGroup, Label, ControlLabel} from 'react-bootstrap';

class SearchBar2 extends Component {
    constructor(props) {
      super(props);

      this.beginningWeekTextChange = this.beginningWeekTextChange.bind(this);
      this.endingWeekTextChange = this.endingWeekTextChange.bind(this);
      this.positionTextChange = this.positionTextChange.bind(this);
      this.minGamesTextChange = this.minGamesTextChange.bind(this);

      console.log("contructor for toolbar: " + this.endingWeekTextChange);
      console.log("maxWeek: " + this.props.maxWeek);
      console.log("endingWeek: " + this.props.endingWeek);
      //this.updateData = this.updateData.bind(this);
    }
  
    beginningWeekTextChange(e) {
      this.props.onBeginningWeekTextChange(e.target.value);
    }
    endingWeekTextChange(e) {
      this.props.onEndingWeekTextChange(e.target.value);
    }
    positionTextChange(e) {
      this.props.onPositionTextChange(e.target.value);
      //this.props.onUpdateData();
    }
    minGamesTextChange(e) {
      this.props.onMinGamesTextChange(e.target.value);
    }

    updateData(e) {
      this.props.onUpdateData();
    }
  
    render() {
      const beginningWeek = this.props.beginningWeek;
      console.log("render searchbar2 endingweek: " + this.props.endingWeek);
      //const endingWeek = 11; //this.props.endingWeek;
      const endingWeek = this.props.endingWeek;
      const position = this.props.position;

      console.log("ending week: " + endingWeek);
  
      return (
        
        <Form style={{ width: '100p%'}}>
        <Row >
          <Col md={3}>
            <FormGroup controlId="formControlsSelect">
                <ControlLabel>Select</ControlLabel>
                <FormControl componentClass="select" 
                            placeholder="select" 
                            value={position} 
                            onChange={this.positionTextChange}>
                    <option value="0">All Players</option>
                    <option value="1">Goalkeeper</option>
                    <option value="2">Defender</option>
                    <option value="3">Midfielder</option>
                    <option value='4'>Forward</option>

                </FormControl>
            </FormGroup>

          </Col>
          <Col md={3}>
          <FormGroup controlId="formControlsSelect">
                <ControlLabel>Start Week</ControlLabel>
                <FormControl componentClass="select" 
                            placeholder="select" 
                            value={beginningWeek} 
                            onChange={this.beginningWeekTextChange} >
                {Array.apply(null, Array(this.props.maxWeek)).map(function (_, i) {return i;}).map((e, key) => {
                    return <option key={e} value={e + 1}>{e + 1}</option>;
                })}
                </FormControl>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup controlId="formControlsSelect">
                <ControlLabel>End Week</ControlLabel>
                <FormControl componentClass="select" 
                                value={this.props.endingWeek} 
                                placeholder="Ending week..."
                                onChange={this.endingWeekTextChange} >
                {Array.apply(null, Array(this.props.maxWeek)).map(function (_, i) {return i;}).map((e, key) => {
                    return <option key={e + 1} value={e + 1}>{e + 1}</option>;
                })}
                </FormControl>
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup controlId="formControlsSelect">
                <ControlLabel>Min Games</ControlLabel>
                <FormControl componentClass="select" 
                                value={this.props.minGames} 
                                placeholder="Ending week..."
                                onChange={this.minGamesTextChange} >
                {Array.apply(null, Array(this.props.maxWeek)).map(function (_, i) {return i;}).map((e, key) => {
                    return <option key={e + 1} value={e + 1}>{e + 1}</option>;
                })}
                </FormControl>
            </FormGroup>
          </Col>          
        </Row>
        </Form>
      );
    }
  }

  export default SearchBar2;