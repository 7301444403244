import React, { Component} from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

class PlayerGrid extends Component {
    constructor(props) {
        super(props);
        this.dataCacheLength = -1;
        this.state = {
            columnDefs: [
                {headerName: "Week", field:"round", width: 75, pinned: "left"},

                {headerName: "total_points", field:"total_points", width: 80 },
                {headerName: "Pts", field:"total_points", width: 80 },
                {headerName: "Bonus", field:"bonus", width: 80 },

                {headerName: "Mins", field:"minutes", width: 80 },
                {headerName: "G", field:"goals_scored", width: 60 },
                {headerName: "Asst", field:"assists", width: 75},

                {headerName: "Val", field:"value", width: 40},


                {headerName: "Pass Att", field:"attempted_passes", width: 60},
                {headerName: "Pass Comp", field:"completed_passes", width: 90 },
                {headerName: "Key Passes", field:"key_passes", width: 90 },
                {headerName: "Crosses", field:"open_play_crosses", width: 90 },
                
                
                {headerName: "big_chances_created", field:"big_chances_created", width: 40 },
                {headerName: "big_chances_missed", field:"big_chances_missed", width: 80 },
                
                {headerName: "BPS", field:"BPS", width: 70 },
                {headerName: "CS", field:"clean_sheets", width: 80 },
                
                {headerName: "CBI", field:"clearances_blocks_interceptions", width: 90 },
                
                {headerName: "Tackled", field:"tackled", width: 80 },
                {headerName: "Tackles", field:"tackles", width: 90 },  

                
                {headerName: "dribbles", field:"dribbles", width: 90 },
                {headerName: "ea_index", field:"ea_index", width: 80 },
                
                {headerName: "goals_conceded", field:"goals_conceded", width: 80 },
                
                {headerName: "ict_index", field:"ict_index", width: 90 },

                {headerName: "creativity", field:"creativity", width: 80 },
                {headerName: "influence", field:"influence", width: 90 },
                {headerName: "threat", field:"threat", width: 90 },
                
                {headerName: "loaned_in", field:"loaned_in", width: 90 },
                {headerName: "loaned_out", field:"loaned_out", width: 90 },
                {headerName: "transfers_balance", field:"transfers_balance", width: 90 },
                {headerName: "transfers_in", field:"transfers_in", width: 90 },
                {headerName: "transfers_out", field:"transfers_out", width: 90 },
                {headerName: "Selected", field:"selected", width: 90 },

                {headerName: "FOUL", field:"fouls", width: 90 },
                {headerName: "RC", field:"red_cards", width: 90 },
                {headerName: "YC", field:"yellow_cards", width: 90 },
                {headerName: "OG", field:"own_goals", width: 90 },
                /*
                      "offside": 0,

      "opponent_team": 13,
      "penalties_conceded": 0,
      "penalties_missed": 0,
      "penalties_saved": 0,
      "recoveries": 9,
      "saves": 6,
      "target_missed": 0,
      "team_a_score": 2,
      "team_h_score": 0,
      "total_points": 3,
      "was_home": true,
      "winning_goals": 0,
      */

            ],
        };
    }


    shouldComponentUpdate(nextProps, nextState){
        // fires for every change 
        console.log("shouldComponentUpdate WeeklyGrid.js");

        //TODO: this is not good, better error checking
        if (nextProps.rowData == null) return true;
        //console.log(nextProps.rowData.length);
        //console.log(this.props.rowData.length);
        if (this.props.rowData.length === nextProps.rowData.length){
            //console.log("not equal data");
            return false;
        }
        return true;
      }

    render() {
        console.log("render WeeklyGrid.js");
        //if (!this.props.rowData) return '';

        return (
            <div className="ag-theme-balham" style={{ height: '400px', width: '100%' }}>
            <AgGridReact    enableFilter={true} 
                            enableSorting={true} 
                            columnDefs={this.state.columnDefs} 
                            rowData={this.props.rowData} >
            </AgGridReact>
            </div>
            
        )
    }
}

export default PlayerGrid;