import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';


class About extends Component {


    constructor() {
        super();
        this.state = {

        };
    }
  

    render() {

      return (
        <>
            {/* <div className="container ag-theme-balham" 
                 style={{ height: '650px', width: '80%', marginTop: '70px', paddingTop: '10px', fontSize: '10px' }}>

                <span className="Titan" style ={{ fontSize: '20px'}} >
                    <span className="Red">About/News</span>
                </span> 
                <div style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: '12px'}}> */}
                <Container>
                <Paper>
                    <Card style={{width: '100%'}}><br/><br/><br/><br/><br/><br/><br/>
                    Revamping for 2020/21. I know its rough right now.<br/><br/>I'm cleaning up everything. Am adding new insights and pages.<br/><br/>
                    Totally redid the backend (that you cant see) but everything is running in Google Cloud--no more Virtual PCS.<br/><br/>
                    Some things are gonna be slower till I optimize, right now its kind of just adding content... please tell your friends!
                    </Card>
                </Paper>
                </Container>
                {/* </div>
            </div> */}


      </>)
    } 
}

export default About;